import React, { useRef, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import ProductSchema from '../../validations/productSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box, Paper } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '../../components/alert';
import AWS from 'aws-sdk';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import InputAdornment from '@mui/material/InputAdornment';
import Loader from '../../components/loader';

window.Buffer = window.Buffer || require("buffer").Buffer;


export default function Create(props) {
    const navigate = useNavigate();
    const dialogBox = useRef()
    const { openPopup, setOpenPopup, bankData } = props;
    const [apiErrors, setApiErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const bundle = localStorage.getItem("wd_token");
    const [templatesData, setTemplatesData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
    }, []);


    const formik = useFormik({
        initialValues: {
            name: '',
            bank_id: '',
        },
        enableReinitialize: true,
        validationSchema: ProductSchema,

        onSubmit: async (initialValues, { resetForm }) => {

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };
            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/cards`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if ('error' in data) {
                        resetForm();
                        setApiErrors(data.errors);
                    } else if ('success' in data) {
                        resetForm();
                        setAlert(true);
                        setOpenPopup(false);
                        setType('success');
                        setMessage(data.message);
                        setTimeout(
                            function () {
                                navigate('/cards');
                            }
                                .bind(this),
                            1000
                        );
                    } else {
                        resetForm();
                        setOpenPopup(false);
                        setAlert(true);
                        setType('error');
                        setMessage('Something went wrong');
                    }
                    setLoading(false);

                });


        }

    });

    const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;


    return (
        <Dialog
            ref={dialogBox}
            open={openPopup}
            onClose={() => { setOpenPopup(false) }}
            maxWidth="md"
        >
            <FormikProvider value={formik} >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '20px', border: '1px solid #1B191F', borderRadius: '5px' }}>
                    <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Add Card</Typography>
                    <Grid container spacing={3} >
                        {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                            <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color: '#FFFFFF' }}>
                                {
                                    Object.keys(apiErrors).map((item, i) => (
                                        <Typography key={i}>{apiErrors[item]}</Typography>
                                    ))
                                }
                            </Box>
                        </Grid>}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Name"
                                {...getFieldProps('name')}
                                size="small"
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">Bank Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Bank"
                                    size="small"
                                    {...getFieldProps('bank_id')}
                                    error={Boolean(touched.bank_id && errors.bank_id)}
                                    helpertext={touched.bank_id && errors.bank_id}

                                >
                                    <MenuItem value="" disabled>Select Bank</MenuItem>
                                    {bankData.length > 0 && bankData.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={values.status} onChange={(event, checked) => setFieldValue('status', checked ? true : false)} />} label="Status" />
                            </FormGroup>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button variant="contained" type="submit" color="secondary" sx={{ float: 'right', textTransform: 'capitalize', width: '20%' }}>Save</Button>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>

        </Dialog >
    )
}