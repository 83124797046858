import * as React from "react";
import {useRoutes } from 'react-router-dom';
import Dashboard from './layouts/index';
// import Footer from './layouts/dashboard/footer';
import Login from './pages/login';
import Index from './pages/index';
import Home from './pages/home';
import MobileScreen from './pages/mobileScreen';
import DesktopScreen from './pages/desktopScreen';
import Cards from './pages/cards/index';
import Banks from './pages/banks/index';
import Encryption from './pages/encryption/index';
import CashkaroTemplates from './pages/cashkaroTemplates/index';
import BankkaroTemplates from './pages/bankkaroTemplates/index';
import ProductCreate from './pages/cards/create';
import ProductEdit from './pages/cards/edit';
import Exits from './pages/exits/index';
import Users from './pages/users/index';
import UpdatePassword from './pages/updatePassword';
// import Loader from './component/loader';
import Logout from './components/logout';
export default function Router() {
  const token = localStorage.getItem('wd_token');
  const role = localStorage.getItem('wd_bkn');
  let isLoggedIn = false;
  if (token && role) {
    isLoggedIn = true;
  }
  return useRoutes(isLoggedIn ? role == 'Admin' ? [
    {
      path: "/",
      element: <Dashboard />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/banks", element: <Banks /> },
        { path: "/cards", element: <Cards /> },
        { path: "/encryption", element: <Encryption /> },
        { path: "/cards/create", element: <ProductCreate /> },
        { path: "/cards/edit", element: <ProductEdit /> },
        { path: "/exits", element: <Exits /> },
        { path: "/callers", element: <Users /> },
        { path: "/ck-templates", element: <CashkaroTemplates /> },
        { path: "/bk-templates", element: <BankkaroTemplates /> },
        // { path: "/templates/create", element: <TemplateCreate /> },
        { path: "*", element: <Home /> },
        
      ]
    },
    { path: "/logout", element: <Logout /> },
  ] :[ 
    { path: "/", element: <Index /> },{ path: "*", element: <Index /> },
    { path: "/update-password", element: <UpdatePassword /> },
    { path: "/logout", element: <Logout /> }]: [
    {
      path: '/',
      element: < Login />,
      children: [
        { path: '/login', element: < Login /> },
      ]
    }]
  );
}