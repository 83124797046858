import * as Yup from 'yup';
// add validation to driver form
const mobileRegExp = /^[6-9][0-9]{9}$|^[C][0-9]{10,}$/;
const formSchema = Yup.object().shape({
    type: Yup.string().required('Option is required'),
    bank_id: Yup.string().required('Bank is required.'),
    card_id: Yup.string().required('Card is required.'),
    sitution_id: Yup.string().required('Sitution is required.'),
    // name: Yup.string().required('Password is required.'),
    // mobile: Yup.string().required('Kindly enter a valid mobile number').matches(mobileRegExp, 'Mobile number is not valid')
    mobile: Yup.string().required('Kindly enter a valid mobile number').matches(mobileRegExp, 'Mobile number is not valid')
})

export default formSchema;
