import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Grid, Stack, Box } from '@mui/material';
import CreatePopup from './create';
import EditPopup from './edit';
import FilterPopup from './filter';
import Alert from '../../components/alert';
import Loader from '../../components/loader';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07090A",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export default function Index() {
  const navigate = useNavigate();
  const dialogBox = useRef()
  const [open, setOpen] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [importLoad, setImportLoad] = useState(false);
  const [exportLoad, setExportLoad] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  // const [openDelete, setOpenDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [type, setType] = useState('');
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
  });
  const [onFilter, setOnFilters] = useState(false);
  const [state, setState] = useState()
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(25);
  const [data_id, setDataID] = useState(null);
  const [record, setRecord] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [message, setMessage] = useState('');
  const bundle = localStorage.getItem("wd_token");

  const callApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/banks?name=${filters.name}&page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data.dataItems.length !== undefined) {
            setData(data.dataItems);
            setTotalUsers(data.totalItems);
            setTotalItems(data.totalItems % size ? (Math.floor(data.totalItems / size) + 1) : Math.floor(data.totalItems / size));
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });
  }
  useEffect(() => {

    callApi();
  }, [page, size]);// eslint-disable-line react-hooks/exhaustive-deps

  const openCreatePopup = () => {
    setOpenCreate(!openCreate);
  };
  const openEditPopup = (e, id) => {
    setRecord(data.find(o => o.id === id));
    setOpenEdit(!openEdit);
  };
  // const openDeletePopup = (e, id) => {
  //   setDataID(id);
  //   setOpenDelete(!openDelete);
  // };
  // console.log(filters);
  if (onFilter === true) {
    callApi();
    setOnFilters(false);
  }

  const handlePagination = (e, p) => {
    setPage(p - 1);
  }
  const refreshTable = () => {
    callApi();
  };
 
  

  return (
    <>
      <Accordion sx={{ border: '1px solid gray' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterPopup setFilters={setFilters} onFilter={setOnFilters} />
        </AccordionDetails>
      </Accordion>

      <Stack direction="row" justifyContent="space-between" >
        <Typography variant="h5" sx={{ paddingY: '10px' }}>Banks</Typography>
        {isLoading === false &&
        <Stack direction="row" alignItems="right" spacing={1} sx={{ marginY: '10px', }}>
         
          <Stack direction="column">
          <Button variant="contained" startIcon={<AddIcon />} sx={{  backgroundColor: '#1b5e20' }} onClick={openCreatePopup}> Add </Button>
          </Stack>
        </Stack>
        }
      </Stack>
      {isLoading === true ? <Loader show={isLoading} /> :
        <>
          <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ textAlign:'center',  fontSize: '13px', padding: '4px'  }}>S. No.</StyledTableCell>
                  <StyledTableCell sx={{ textAlign:'center',  fontSize: '13px', padding: '4px'  }}>Bank Name</StyledTableCell>
                  <StyledTableCell sx={{ textAlign:'center',  fontSize: '13px', padding: '4px'  }}>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? data.map((item) => (
                  <React.Fragment key={item.id} >
                    <TableRow >
                      <TableCell sx={{ textAlign:'center', margin: '0px', fontSize: '13px', padding: '0px' }}>{item.id}</TableCell>
                      <TableCell sx={{ textAlign:'center', margin: '0px', fontSize: '13px', padding: '0px' }}>{item.name}</TableCell>
                      <TableCell sx={{ textAlign:'center', margin: '0px', fontSize: '13px', padding: '0px' }}>
                        <IconButton sx={{ color: '#1a237e' }} onClick={(e) => openEditPopup(e, item.id)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                   
                  </React.Fragment>
                )) : <TableRow>
                  <TableCell component="th" scope="item" colSpan={3}>No Record Found</TableCell>
                </TableRow>
                }
              </TableBody>

            </Table>
          </TableContainer>

          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}><Typography sx={{paddingTop:'20px', paddingRight:'10px', fontWeight:'600'}}>Total Banks: {totalUsers}</Typography></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {data.length > 0 ? <Pagination count={totalItems} page={page + 1} color="error" sx={{ paddingY: '20px' }} onChange={(e, page) => handlePagination(e, page)} /> : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormControl sx={{ minWidth: 100, marginTop: '15px', float: 'right'}} size="small">
              <InputLabel id="demo-simple-select-helper-label">Size Per Page</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  label="Size Per Page"
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem value="25">25</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                  <MenuItem value="500">500</MenuItem>
                  <MenuItem value="1000">1000</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> </>}
      <CreatePopup openPopup={openCreate} setOpenPopup={openCreatePopup} setMessage={setMessage} setAlert={setAlert} setType={setType} refreshTable={refreshTable} />
      <EditPopup openPopup={openEdit} setOpenPopup={openEditPopup} setMessage={setMessage} setAlert={setAlert} setType={setType} refreshTable={refreshTable} record={record} />
      <Alert open={alert} type={type} message={message} setOpen={setAlert} />
    </>
  );
}
