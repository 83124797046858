import React, { useRef, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BankSchema from '../../validations/bankSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import AWS from 'aws-sdk';
import * as Yup from 'yup';
import SuccessImg from '../../images/tick.svg'
import OTPScreen from './otp'
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Create(props) {
    const dialogBox = useRef()
    const { openSuccess, setOpenSuccess } = props;
  
  
    return (
        <Dialog
            ref={dialogBox}
            open={openSuccess}
            onClose={() => { setOpenSuccess(false) }}
            maxWidth="xs"
        >
            <div style={{ textAlign: 'right' }}>
                <CloseIcon sx={{ color: '#000000', fontSize: '20px', marginRight: '10px', }} onClick={() => setOpenSuccess(false)} />
            </div>
            <div style={{padding:'20px'}}>
            <div style={{ textAlign: 'center' }}>
                <img src={SuccessImg} style={{ marginBottom:'20px'}} />
                <p style={{fontFamily:'Metropolis', fontSize:'18px', fontWeight:'700', letterSpacing:'-0.72px'}}> Your message has been sent</p>
            </div>
            </div>
        </Dialog>
    )
}