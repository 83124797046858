import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Grid, Stack, Box } from '@mui/material';
import CreatePopup from './create';
// import EditPopup from './edit';
// import FilterPopup from './filter';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import * as XLSX from 'xlsx/xlsx.mjs';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import UploadIcon from '@mui/icons-material/Upload';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07090A",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export default function Index() {

  const navigate = useNavigate();
  const dialogBox = useRef()
  const [open, setOpen] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [importLoad, setImportLoad] = useState(false);
  const [exportLoad, setExportLoad] = useState(false);
  const [totalTemplates, setTotalTemplates] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  // const [openDelete, setOpenDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [type, setType] = useState('');
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    bank_name: '',
    pincode: '',
  });
  const [openConfirmPending, setOpenConfirmPending] = useState(false);
  const [onFilter, setOnFilters] = useState(false);
  const [state, setState] = useState()
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(25);
  const [data_id, setDataID] = useState(null);
  const [record, setRecord] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [message, setMessage] = useState('');
  const [templatesData, setTemplatesData] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const bundle = localStorage.getItem("wd_token");
  const callTemplatesApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/cards/templates/all`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data.length !== undefined) {
            setTemplatesData(data);
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });


  }
  const callCardTemplatesApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates/cards-templates`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data?.success == 1) {
            setTemplatesData(data?.data?.templates);
            setCardsData(data?.data?.cards);
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });


  }


  const callApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates?type=bankkaro&page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data.dataItems.length !== undefined) {
            setData(data.dataItems);
            setTotalTemplates(data.totalItems);
            setTotalItems(data.totalItems % size ? (Math.floor(data.totalItems / size) + 1) : Math.floor(data.totalItems / size));
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });
  }

  useEffect(() => {
    callCardTemplatesApi();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    callApi();
  }, [page, size]);// eslint-disable-line react-hooks/exhaustive-deps

  const openCreatePopup = () => {
    setOpenCreate(!openCreate);
  };
  const openEditPopup = (e, id) => {
    setDataID(id);
    setRecord(data.find(o => o.id === id));
    setOpenEdit(!openEdit);
  };
  // const openDeletePopup = (e, id) => {
  //   setDataID(id);
  //   setOpenDelete(!openDelete);
  // };
  // console.log(filters);
  if (onFilter === true) {
    callApi();
    setOnFilters(false);
  }

  const handlePagination = (e, p) => {
    setPage(p - 1);
  }
  const refreshTable = () => {
    callApi();
  };

  const statusTemplate = async (item) => {
    setLoader(true)
    const initialValues = {
      id: item?.id,
      status: !item.status,
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bundle}`
      },
      body: JSON.stringify(initialValues)
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates/status-template`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('success' in data) {
          setAlert(true);
          refreshTable();
          setType('success');
          setMessage(data.message);
        } else {
          setAlert(true);
          setType('error');
          setMessage('Something went wrong');
        }
        setLoader(false)
      });
  }
  return (
    <>
      {/* <Accordion sx={{ border: '1px solid gray' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterPopup setFilters={setFilters} onFilter={setOnFilters} />
        </AccordionDetails>
      </Accordion> */}

      <Stack direction="row" justifyContent="space-between" >
        <Typography variant="h5" sx={{ paddingY: '10px' }}>BankKaro Templates</Typography>
        <Stack direction="column">
          <Button variant="contained" startIcon={<AddIcon />} sx={{ backgroundColor: '#1b5e20' }} onClick={openCreatePopup}> Add </Button>
          {/* <Button variant="contained" startIcon={<AddIcon />} sx={{  backgroundColor: '#1b5e20' }} onClick={() => navigate('/templates/create')}> Add </Button> */}
        </Stack>
      </Stack>
      {isLoading === true ? <Loader show={isLoading} /> :
        <>
          <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>S. No.</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Situation</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Card Name</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Status</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? data.map((item) => (
                  <React.Fragment key={item.id} >
                    <TableRow >
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '10px' }}>{item.id}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '10px' }}>{item?.sitution}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '10px' }}>{item?.cards?.name}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '10px' }}>{item.status === true ? <span style={{ color: '#1b5e20' }}>Active</span> : <span style={{ color: '#DB0011' }}>Inactive</span>}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '10px' }}>
                        <Button variant="contained" sx={{ backgroundColor: (item.status === true ? '#DB0011' : '#1b5e20') }} onClick={(e) => statusTemplate(item)}>
                          {item.status === true ? <span style={{ color: '#FFF' }}>Deactivate</span> : <span style={{ color: '#FFF' }}>Activate</span>}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )) : <TableRow>
                  <TableCell component="th" scope="item" colSpan={3}>No Record Found</TableCell>
                </TableRow>
                }
              </TableBody>

            </Table>
          </TableContainer>

          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}><Typography sx={{ paddingTop: '20px', paddingRight: '10px', fontWeight: '600' }}>Total Templates: {totalTemplates}</Typography></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {data.length > 0 ? <Pagination count={totalItems} page={page + 1} color="error" sx={{ paddingY: '20px' }} onChange={(e, page) => handlePagination(e, page)} /> : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormControl sx={{ minWidth: 100, marginTop: '15px', float: 'right' }} size="small">
                <InputLabel id="demo-simple-select-helper-label">Size Per Page</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  label="Size Per Page"
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem value="25">25</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                  <MenuItem value="500">500</MenuItem>
                  <MenuItem value="1000">1000</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> </>}

      <CreatePopup openPopup={openCreate} setOpenPopup={openCreatePopup} setMessage={setMessage} setAlert={setAlert} setType={setType} refreshTable={refreshTable} templates={templatesData} cards={cardsData} />
      {/* <EditPopup openPopup={openEdit} setOpenPopup={openEditPopup} setMessage={setMessage} setAlert={setAlert} setType={setType}  refreshTable={refreshTable}  record={record} templates={templatesData}  cards= {cardsData}/> */}
      <Alert open={alert} type={type} message={message} setOpen={setAlert} />
    </>
  );
}
