import React from 'react';
import * as Icon from '@mui/icons-material';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <Icon.Home  />
  },
 
  {
    title: 'Banks',
    path: '/banks',
    icon: <Icon.AccountBalance />
  },
  {
    title: 'Cards',
    path: '/cards',
    icon: <Icon.Category />
  },
  {
    title: 'CK Templates',
    path: '/ck-templates',
    icon: <Icon.ForwardToInbox />
  },
  {
    title: 'BK Templates',
    path: '/bk-templates',
    icon: <Icon.ForwardToInbox />
  },

  {
    title: 'Callers',
    path: '/callers',
    icon: <Icon.Groups />
  },
   {
    title: 'Encryption',
    path: '/encryption',
    icon: <Icon.Key />
  },
  
  // {
  //   title: 'Exits',
  //   path: '/exits',
  //   icon: <Icon.ExitToApp />
  // },
  
];
