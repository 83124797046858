import * as React from 'react';
import LoginFormSchema from '../validations/loginSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { alpha, styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Alert from './../components/alert'
import useMediaQuery from '@mui/material/useMediaQuery';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { TextField, Typography, Button } from '@mui/material';
import Fab from '@mui/material/Fab';
import MailForm from './popup/mailForm'
import BankKaroLogo from '../images/BANKKARO.svg'
import CryptoJS from "crypto-js";
const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
      padding: '18px 24px',
      border: '1px solid var(--Stroke-Grey, #D9E3EC)'
    },
    '&:hover fieldset': {
      borderRadius: '8px',
      border: '1px solid var(--Stroke-Grey, #D9E3EC)',
      color:'#DB0011'
    },
    '& .Mui-focused fieldset': {
      borderRadius: '8px',
      border: '1px solid var(--Stroke-Grey, #D9E3EC)',
      color:'#DB0011'
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Metropolis', // Adjust the font size as needed
      fontSize: '12px', // Adjust the font size as needed
      fontWeight: 400, // Adjust the font size as needed
      fontStyle: 'normal', // Adjust the font size as needed
      color: 'var(--Text-Inactive, #5C5C5C)'
    },
  },
});



export default function Login() {
  const navigate = useNavigate();
  const screenSize = useMediaQuery('(min-width:600px)');
  const [alert, setAlert] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [openMail, setOpenMail] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginFormSchema,
    onSubmit: (initialValues) => {
      console.log(initialValues);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(initialValues),
      };
      fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/auth/login`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log('user', data);
          if (Object.prototype.hasOwnProperty.call(data, "token")) {
            localStorage.setItem("wd_token", data.token);
            localStorage.setItem("wd_bki", data.user.id);
            localStorage.setItem("wd_bkn", data.user.name);
            const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data.user), 'secret key 123').toString();
            localStorage.setItem("wd_bundle", ciphertext);
            setAlert(true);
            setType('success');
            setMessage('Login Successfully');
            navigate('/', { replace: true });
          } else {
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

    }
  });
  const handleLogin = (value) => {
    if (value) {
      navigate('/update-password', { replace: true });
    }

  }

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <Box style={{ backgroundColor: '#FFFFFF', minHeight: '100vh', maxHeight: '100vh', overflow: 'hidden' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: '#07090A', textAlign: 'center' }}>
        <Toolbar sx={{ alignItems: (screenSize ?'center':'flex-start'), display: 'flex', flexDirection: 'column', justifyContent: 'center', width:'100%'}}>
          <img src={BankKaroLogo} style={{}} />
          {/* <Typography variant="h6" noWrap sx={{ textAlign: 'center' }}>
            <span style={{ color: '#DB0011' }}>BANK</span><span style={{ color: '#FFFFFF' }}>KARO</span>
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Grid container >
        <Grid item xs={12} sm={12} md={4} ></Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', justifyContent: 'center', marginX: '20px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CssTextField
                fullWidth
                type="email"
                variant="outlined"
                label="Your login ID"
                {...getFieldProps('email')}
                sx={{
                  marginBottom: '20px', color: '#07090A', '& .MuiInputLabel-root': {
                    fontFamily: 'Metropolis', // Adjust the font size as needed
                    fontSize: '12px', // Adjust the font size as needed
                    fontWeight: 400, // Adjust the font size as needed
                    fontStyle: 'normal', // Adjust the font size as needed
                    color: 'var(--Text-Inactive, #5C5C5C)',
                    left: '10px'
                  }
                }}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <CssTextField
                fullWidth
                type="password"
                variant="outlined"
                label="Password"
                {...getFieldProps('password')}
                sx={{
                  marginBottom: '10px', color: '#07090A', '& .MuiInputLabel-root': {
                    fontFamily: 'Metropolis', // Adjust the font size as needed
                    fontSize: '12px', // Adjust the font size as needed
                    fontWeight: 400, // Adjust the font size as needed
                    fontStyle: 'normal', // Adjust the font size as needed
                    color: 'var(--Text-Inactive, #5C5C5C)',
                    left: '10px',

                  },
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <Typography sx={{
                fontFamily: 'Metropolis', // Adjust the font size as needed
                fontSize: '12px', // Adjust the font size as needed
                fontWeight: 500, // Adjust the font size as needed
                fontStyle: 'normal', // Adjust the font size as needed
                color: 'var(--Error-Red, #D41000)',
                lineHeight: '16px',
                cursor: 'pointer', marginY: '20px'
              }} onClick={() => setOpenMail(true)}>Forgot email or password?</Typography>
              <div style={{ display: 'flex', justifyContent: "center" }} >
                <Button variant="extended" type="submit" sx={{
                  width: '196px', height: '56px', fontSize: '12px', // Adjust the font size as needed
                  fontWeight: 500, // Adjust the font size as needed
                  fontStyle: 'normal', // Adjust the font size as needed
                  color: 'var(--Error-Red, #D41000)',
                  lineHeight: '20px', bottom: '-20px', borderRadius: '40px',
                  padding: '8px', backgroundColor: '#DB0011', color: 'white', ':hover': { bgcolor: '#DB0011', color: '#FFFFFF', }
                }} >Login</Button>
              </div>
            </Form>
          </FormikProvider>
        </Grid>
      </Grid>

      <MailForm setOpenMail={setOpenMail} openMail={openMail} handleLogin={handleLogin} />
      <Alert open={alert} type={type} setOpen={setAlert} message={message} />
    </Box>
  );
}
