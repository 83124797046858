import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import PhoneFrame from '../../phone.png';

export default function Login(props) {
    const navigate = useNavigate();
    const { openScreen, sample } = props;
    const formatText = (inputText) => {
        // Replace *text* with <b>text</b> for bold
        let formattedText = inputText.replace(/\*(.*?)\*/g, '<b>$1</b>');
    
        // Replace _text_ with <i>text</i> for italic
        formattedText = formattedText.replace(/_(.*?)_/g, '<i>$1</i>');
    
        // Replace ~text~ with <s>text</s> for strikethrough
        formattedText = formattedText.replace(/~(.*?)~/g, '<s>$1</s>');
    
        // Replace `text` with <code>text</code> for monospace
        formattedText = formattedText.replace(/`(.*?)`/g, '<code>$1</code>');
    
        // Replace \n with <br> for new lines
        formattedText = formattedText.replace(/\n/g, '<br>');
    
        return {__html:`${formattedText}`};
    }

    return (
        <Box style={{ backgroundColor: '#FFFFFF', minHeight: '100vh', maxHeight: '100vh',overflow:'hidden' }}>
            <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'right' }}>
                <CloseIcon sx={{ color: '#000000', fontSize:'20px', marginRight:'10px' }} onClick={()=>openScreen(false)}/>
            </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                    <Typography variant='h5' sx={{ textDecoration: 'underline', textUnderlineOffset: '15px' }}>Preview</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'center', justifyContent: 'center' }}>
                    <Box sx={{ backgroundImage: `url(${PhoneFrame})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundSize: '100%', width: '70%', minHeight: '100vh', }} >
                        <div dangerouslySetInnerHTML={formatText(sample)}  style={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'white', alignSelf: 'flex-end', marginInline: '20px', borderRadius: '5px', padding: '10px', fontSize: '10px', maxWidth: '100%' }}>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
