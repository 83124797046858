import * as React from 'react';
import FormSchema from '../validations/formSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { LogOut } from 'react-feather';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '../components/alert'
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PhoneFrame from './../phone.png';
import Loader from '../components/loader';
import SuccessPopup from './popup/success'
import FormHelperText from '@mui/material/FormHelperText';
import { alpha, styled } from '@mui/material/styles';
import Logo from '../images/BANKKARO.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1px solid #cbcbcb'
      },
      '&:hover fieldset': {
        borderRadius: '8px',
        border: '1px solid var(--Stroke-Grey, #cbcbcb)'
      },
      '&.Mui-focused fieldset': {
        borderRadius: '8px',
        border: '1px solid var(--Stroke-Grey, #cbcbcb)'
      },
     
    },
  });

export default function Login(props) {
    const navigate = useNavigate();
    const { openPopup, setOpenPopup } = props;
    const [bankData, setBankData] = useState([]);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [screenPopup, setScreenPopup] = useState(false)
    const [message, setMessage] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [cardData, setCardData] = useState([]);
    const [bodyData, setBodyData] = useState([]);
    const [sample, setSample] = useState('');
    const [oldSample, setOldSample] = useState('');
    const [situtionData, setSitutionData] = useState([]);
    const [titlesData, setTitlesData] = useState([]);
    const [selectedBank, setSelectedBank] = useState({})
    const [selectedCard, setSelectedCard] = useState({})
    const [selectedSitution, setSelectedSitution] = useState({})
    const [selectTemplate, setSelectedTemplate] = useState({})
    const [isLoading, setLoader] = useState(false);
    const bundle = localStorage.getItem("wd_token");
    const user_name = localStorage.getItem("wd_bkn");
    
    const callDropDownApi = (type) => {
        setLoader(true);
        if (bundle != null) {
          // setAccessToken(bundle);
        } else {
          setAlert(true);
          setType('error');
          setMessage('No Token Found');
          navigate('/login', { replace: true });
        }
        const requestOptions = {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${bundle}`
          },
        };
    
        fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates/dropdown?type=${type}`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            resetData()
            if ('error' in data) {
              setLoader(false)
              setAlert(true);
              setType('error');
              setMessage(data.message);
            } else {
              if (data?.success == 1) {
                setBankData(data?.data);
                setLoader(false);
              } else {
                setLoader(false)
                setAlert(true);
                setType('error');
                setMessage(data.message);
              }
            }
          });
      }
      useEffect(() => {
        callDropDownApi('cashkaro');
      }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const formik = useFormik({
        initialValues: {
            type: 'cashkaro',
            bank_id: '',
            card_id: '',
            sitution_id: '',
            name: '',
            mobile: '',
        },
        validationSchema: FormSchema,
        onSubmit: async (initialValues, { resetForm }) => {
            let checkSuccess = true
            setLoader(true)

            await bodyData.map((item, index) => {
                if (!titlesData[index]?.check) {
                    let span1 = document.getElementById("span_" + index);
                    if (item?.value === '') {
                        span1.style.display = "block"
                        checkSuccess = false
                    } else {
                        span1.style.display = "none"
                    }
                }
            })
            if (!checkSuccess) {
                return
            }
            let updateTemplate = selectTemplate
            updateTemplate.body = bodyData
            initialValues.template = updateTemplate
            initialValues.sample = sample
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues),
            };
            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates/send-template`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log('response', data);
                    if ("success" in data) {
                        // resetData()
                        // resetForm()
                        setOpenSuccess(true)
                        // setAlert(true);
                        // setType('success');
                        // setMessage('Message sent successfully');
                    } else {
                        setAlert(true);
                        setType('error');
                        setMessage(data.message);
                    }
                    setLoader(false)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
    });
    const handleCard = (id) => {
        const cards = bankData.find(item => item.id === id);
        setSelectedCard({})
        setCardData(cards ? cards.cards : []);
    }
    const handleSitution = (id) => {
        const templates = cardData.find(item => item.id === id);
        setSelectedSitution({})
        setSitutionData(templates ? templates.templates : []);
    }
    const situtionChange = async (temp) => {
        let data = JSON.parse(temp?.template)
        // setSample(temp?.sample)
        let titless = JSON.parse(temp?.titles)
        setTitlesData(titless)
        setSelectedTemplate(data)
        setOldSample(temp?.sample)

        if (data?.body) {
            await data?.body.filter((item, index) => {
                if (titless[index]?.check) {
                    item.text = user_name
                }
            })
            setBodyData(data?.body)
            let newSample = await replacePlaceholders(temp?.sample, data.body)
            setSample(newSample)
            // setFieldValue("name", data?.body[0].text);
        } else {
            console.log("no body data found")
        }
    }
    const handleChange = async (value, type, index) => {

        const newValue = value;
        const updatedBodyData = [...bodyData];
        updatedBodyData[index] = {
            text: newValue
        };
        let newSample = await replacePlaceholders(oldSample, updatedBodyData)
        setSample(newSample)
        setBodyData(updatedBodyData);
    }
    const openScreen = (newOpen) => {
        console.log("hello")
        setScreenPopup(newOpen);
    };
    const handleConfirm = (value) => {
        setOpen(value);
    };
    const handleNameChange = (value) => {
        setFieldValue("name", value);
        handleChange(value, 'body', 0)
    }
    function replacePlaceholders(text, values) {
        // Use a regular expression to find all placeholders like {{1}}, {{2}}, etc.
        const regex = /{{(\d+)}}/g;
        // Replace each placeholder with its corresponding value
        const replacedText = text.replace(regex, (match, index) => {
            const valueIndex = parseInt(index, 10) - 1; // Subtract 1 to convert to array index
            return values[valueIndex] !== undefined ? values[valueIndex].text : match;
        });
        return replacedText;
    }

    const formatText = (inputText) => {
        let formattedText = inputText.replace(/\*(.*?)\*/g, '<b>$1</b>');
        formattedText = formattedText.replace(/_(.*?)_/g, '<i>$1</i>');
        formattedText = formattedText.replace(/~(.*?)~/g, '<s>$1</s>');
        formattedText = formattedText.replace(/`(.*?)`/g, '<code>$1</code>');
        formattedText = formattedText.replace(/\n/g, '<br>');
        return { __html: `${formattedText}` };
    }
    const resetData = () => {
        setCardData([]);
        setBodyData([]);
        setSample('');
        setOldSample('');
        setSitutionData([]);
        setTitlesData([]);
        setSelectedBank({})
        setSelectedCard({})
        setFieldValue('sitution_id','')
        setFieldValue('bank_id','')
        setFieldValue('card_id','')
        setFieldValue('sitution_id','')
        setFieldValue('name','')
        setFieldValue('mobile','')
        setSelectedSitution({})
        setSelectedTemplate({})
    }
    const { errors, values, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
    return (
        <Box style={{ backgroundColor: '#FFFFFF', minHeight: '100vh', maxHeight: '100vh', }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: '#07090A', textAlign: 'center' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: "space-between" }}>
                <img src={Logo} alt='bankkaro logo' />

                    <Link to="/logout" style={{ textDecoration: 'none', fontSize: '12px' }}>
                        <span style={{ color: '#D41000' }}><LogOut /></span> <span style={{ color: '#FFFFFF' }}>Logout</span>
                    </Link>
                </Toolbar>
            </AppBar>
            {isLoading === true ? <Loader show={isLoading} margin={30}/> :
                <Grid container sx={{ marginTop: '70px', paddingX: '40px' }}>
                    <Grid item xs={8} sm={8} md={8} lg={8} mt={2}>
                        <Typography sx={{ fontSize: '24px', fontWeight: '700', fontFamily: 'Metropolis' }}>Welcome, {user_name}</Typography>
                        <FormikProvider value={formik} mt={2}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} sm={4} md={4} lg={4} mt={3}>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="type"
                                                {...getFieldProps('type')}
                                                error={Boolean(touched.type && errors.type)}
                                            >
                                                <FormControlLabel value="cashkaro" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#DB0011' } }} onClick={()=>callDropDownApi('cashkaro')}/>}  label={<span style={{fontSize:'16px',fontFamily:'Metropolis',fontWeight:'700'}}>CashKaro</span>} />
                                                <FormControlLabel value="bankkaro" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#DB0011' } }}  onClick={()=>callDropDownApi('bankkaro')} />} label={<span style={{fontSize:'16px',fontFamily:'Metropolis',fontWeight:'700'}}>BankKaro</span>} />
                                            </RadioGroup>
                                        </FormControl>

                                        <FormHelperText error={Boolean(touched.type && errors.type)}>
                                            {touched.type && errors.type}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={2} mt={3} sx={{marginTop:'48px'}}>

                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Autocomplete
                                            // multiple
                                            size="small"
                                            options={bankData}
                                            value={selectedBank}
                                            getOptionLabel={(option) => option?.name || ''}
                                            onChange={(event, newValue) => {
                                                setSelectedBank(newValue ? newValue : {})
                                                setFieldValue("bank_id", newValue?.id);
                                                handleCard(newValue ? newValue.id : 0)
                                            }}
                                            popupIcon={<KeyboardArrowDownIcon />}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Select bank"
                                                    placeholder="Select bank"
                                                    sx={{
                                                        
                                                        backgroundColor: 'white', borderRadius: '8px',  '& .MuiInputLabel-root': {
                                                          fontFamily: 'Metropolis',
                                                          fontSize: '12px',
                                                          fontWeight: '400',
                              
                                                        },'.MuiSvgIcon-root':{color:'#DB0011 !important'}
                                                      }}
                                                    error={Boolean(touched.bank_id && errors.bank_id)}
                                                />
                                            )}
                                        />
                                        <FormHelperText error={Boolean(touched.bank_id && errors.bank_id)}>
                                            {touched.bank_id && errors.bank_id}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Autocomplete
                                            // multiple
                                            size="small"
                                            value={selectedCard}
                                            options={cardData}
                                            getOptionLabel={(option) => option?.name || ''}
                                            onChange={(event, newValue) => {
                                                setSelectedCard(newValue ? newValue : {})
                                                setFieldValue("card_id", newValue?.id);
                                                handleSitution(newValue ? newValue.id : 0)
                                            }}
                                            popupIcon={<KeyboardArrowDownIcon />}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Select card"
                                                    placeholder="Select card"
                                                    sx={{
                                                        backgroundColor: 'white', borderRadius: '8px',  '& .MuiInputLabel-root':{
                                                          fontFamily: 'Metropolis',
                                                          fontSize: '12px',
                                                          fontWeight: '400',
                                                        },'.MuiSvgIcon-root':{color:'#DB0011 !important'}
                                                      }}
                                                    error={Boolean(touched.card_id && errors.card_id)}
                                                />
                                            )}
                                        />
                                        <FormHelperText error={Boolean(touched.card_id && errors.card_id)}>
                                            {touched.card_id && errors.card_id}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Autocomplete
                                            // multiple
                                            size="small"
                                            options={situtionData}
                                            value={selectedSitution}
                                            getOptionLabel={(option) => option?.sitution || ''}
                                            onChange={(event, newValue) => {
                                                setFieldValue("sitution_id", newValue?.id);
                                                setSelectedSitution(newValue ? newValue : {})
                                                situtionChange(newValue)
                                            }}
                                            popupIcon={<KeyboardArrowDownIcon />}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Select scenario"
                                                    placeholder="Select Scenario"
                                                    sx={{
                                                        backgroundColor: 'white', borderRadius: '8px', '& .MuiInputLabel-root': {
                                                          fontFamily: 'Metropolis',
                                                          fontSize: '12px',
                                                          fontWeight: '400',
                              
                                                        },'.MuiSvgIcon-root':{color:'#DB0011 !important'}
                                                      }}
                                                    error={Boolean(touched.sitution_id && errors.sitution_id)}
                                                />
                                            )}
                                        />
                                        <FormHelperText error={Boolean(touched.sitution_id && errors.sitution_id)}>
                                            {touched.sitution_id && errors.sitution_id}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={3} sx={{marginTop:'64px'}}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}  sx={{marginBottom:'32px'}}>
                                        <Typography sx={{fontSize:'20px',fontWeight:'600',fontFamily:'Metropolis'}}>User Info -</Typography>
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <CssTextField
                                            fullWidth
                                            type="text"
                                            size="small"
                                            variant="outlined"
                                            label={<span style={{fontSize:'12px',fontWeight:'400',fontFamily:'Metropolis'}}>Encrypted phone number</span>}
                                            sx={{ marginBottom: '10px' }}
                                            {...getFieldProps('mobile')}
                                            error={Boolean(touched.mobile && errors.mobile)}
                                            helperText={touched.mobile && errors.mobile}
                                        />
                                    </Grid>
                                    {bodyData && bodyData.length > 0 ? bodyData.map((item, index) => (
                                        !titlesData[index]?.check &&
                                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                                            <CssTextField
                                                key={index}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                value={item.text}
                                                required
                                                // sx={{ marginBottom: '10px' }}
                                                label={<span style={{fontSize:'12px',fontWeight:'400',fontFamily:'Metropolis'}}>{titlesData[index]?.value}</span>}
                                                placeholder=''
                                                onChange={(e) => handleChange(e.target.value, 'body', index)}
                                            />
                                            <span id={`span_${index}`} style={{ marginBottom: '10px', fontSize: '12px', color: '#DB0011', display: 'none' }} >Please add value</span>
                                        </Grid>
                                    )) : null}
                                </Grid>
                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>

                                        <Button variant="extended" type="submit"  sx={{
                                            fontFamily:'Metropolis',
                                            width: '196px', height: '56px', fontSize: '14px', // Adjust the font size as needed
                                            fontWeight: 500, // Adjust the font size as needed
                                            fontStyle: 'normal', // Adjust the font size as needed
                                            
                                            lineHeight: '20px', borderRadius: '40px',
                                            padding: '8px', backgroundColor: '#DB0011', color: 'white', ':hover': { bgcolor: '#DB0011', color: '#FFFFFF', }
                                        }} >Send message</Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} sx={{ paddingX: '2px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display:'flex',justifyContent:'end'}}>
                                <Typography variant='h5' sx={{ textDecoration: 'underline', textUnderlineOffset: '15px',textAlign:'center',display:'flex',justifyContent:'center',width:'60%' }}>Preview</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Box sx={{ backgroundImage: `url(${PhoneFrame})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundSize: '100%', width: '65%', minHeight: '100vh', }} >
                                    <div dangerouslySetInnerHTML={formatText(sample)} style={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'white', alignSelf: 'flex-end', marginInline: '20px', borderRadius: '5px', padding: '10px', fontSize: '10px', maxWidth: '100%' }}>

                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
            <SuccessPopup openSuccess={openSuccess} setOpenSuccess={setOpenSuccess} />
            <Alert open={alert} type={type} setOpen={setAlert} message={message} />
        </Box>
    );
}
