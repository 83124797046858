import * as Yup from 'yup';
// add validation to driver form
const mobileRegExp = /^[6-9][0-9]{9}$/;
const emailPattern = /^[a-zA-Z0-9._-]+@cashkaro\.com$/;
const userFormSchema = Yup.object().shape({
    name: Yup.string().required('Bank Name is required').max(255),
    mobile: Yup.string().required('Kindly enter a valid mobile number').matches(mobileRegExp, 'Mobile number is not valid'),
    email: Yup.string().required('Login ID is required').email('must be an vaild email').max(255).matches(emailPattern, 'Email must have "cashkaro.com" domain'),
    password: Yup.string().required('Password is required.').min(8),
})

export default userFormSchema;
