import * as React from 'react';
import Box from '@mui/material/Box';
import LoaderImg from './loader3.svg'

export default function SimpleBackdrop({ show,margin }) {
  return (
    <Box sx={{margin:`${margin}%`,display:'flex',justifyContent:'center',alignItems:'center'}}  >
      {show && <img alt="loader" src={LoaderImg} />}
    </Box>
  );
}