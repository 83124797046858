import * as React from 'react';
import FormSchema from '../validations/formSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '../components/alert'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Grid from '@mui/material/Grid';
import { TextField, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LogOut } from 'react-feather';
import { Link } from 'react-router-dom';
import ScreenPopup from './popup/screen'
import SuccessPopup from './popup/success'
import Logo from '../images/BANKKARO.svg'
import FormHelperText from '@mui/material/FormHelperText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
      border: '1px solid var(--Stroke-Grey, #D9E3EC)'
    },
    '&:hover fieldset': {
      borderRadius: '8px',
      border: '1px solid var(--Stroke-Grey, #D9E3EC)'
    },
    '&.Mui-focused fieldset': {
      borderRadius: '8px',
      border: '1px solid var(--Stroke-Grey, #D9E3EC)'
    },

  },
});


export default function Login(props) {
  const navigate = useNavigate();
  const { openPopup, setOpenPopup } = props;
  const [bankData, setBankData] = useState([]);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [type, setType] = useState('');
  const [isLoading, setLoader] = useState(false)

  const [message, setMessage] = useState('');
  const [cardData, setCardData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [sample, setSample] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [oldSample, setOldSample] = useState('');
  const [screenPopup, setScreenPopup] = useState(false)
  const [situtionData, setSitutionData] = useState([]);
  const [selectedBank, setSelectedBank] = useState({})
  const [titlesData, setTitlesData] = useState([]);
  const [selectedCard, setSelectedCard] = useState({})
  const [selectedSitution, setSelectedSitution] = useState({})
  const [selectTemplate, setSelectedTemplate] = useState({})
  const bundle = localStorage.getItem("wd_token");
  const user_name = localStorage.getItem("wd_bkn");
  const callDropDownApi = (type) => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates/dropdown?type=${type}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        resetData()
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data?.success == 1) {
            setBankData(data?.data);
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });
  }
  useEffect(() => {
    callDropDownApi('cashkaro');
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  const formik = useFormik({
    initialValues: {
      type: 'cashkaro',
      bank_id: '',
      card_id: '',
      sitution_id: '',
      name: '',
      mobile: '',
    },
    validationSchema: FormSchema,
    onSubmit: async (initialValues, { resetForm }) => {
      let checkSuccess = true
      await bodyData.map((item, index) => {
        if (!titlesData[index]?.check) {
          let span1 = document.getElementById("span_" + index);
          if (item?.value === '') {
            span1.style.display = "block"
            checkSuccess = false
          } else {
            span1.style.display = "none"
          }
        }
      })
    
      if (!checkSuccess) {
        return
      }
      
      let updateTemplate = selectTemplate
      updateTemplate.body = bodyData
      initialValues.template = updateTemplate
      initialValues.sample = sample
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bundle}`
        },
        body: JSON.stringify(initialValues),
      };
      fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates/send-template`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if ("success" in data) {
            // resetData()
            setOpenSuccess(true)
            // resetForm()
            // setAlert(true);
            // setType('success');
            // setMessage('Message sent successfully');
          } else {
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

    }
  });
  const handleCard = (id) => {
    const cards = bankData.find(item => item.id === id);
    setSelectedCard({})
    setCardData(cards ? cards.cards : []);
  }
  const handleSitution = (id) => {
    const templates = cardData.find(item => item.id === id);
    setSelectedSitution({})
    setSitutionData(templates ? templates.templates : []);
  }
  const situtionChange = async (temp) => {
    let data = JSON.parse(temp?.template)
    // setSample(temp?.sample)
    let titless = JSON.parse(temp?.titles)
    setTitlesData(titless)
    setSelectedTemplate(data)
    setOldSample(temp?.sample)

    if (data?.body) {
      await data?.body.filter((item, index) => {
        if (titless[index]?.check) {
          item.text = user_name
        }
      })
      setBodyData(data?.body)
      let newSample = await replacePlaceholders(temp?.sample, data.body)
      setSample(newSample)
      // setFieldValue("name",data?.body[0].text);
    } else {
      console.log("no body data found")
    }
  }
  const handleChange = async (e, type, index) => {
    const newValue = e.target.value;
    const updatedBodyData = [...bodyData];
    updatedBodyData[index] = {
      text: newValue
    };
    let newSample = await replacePlaceholders(oldSample, updatedBodyData)
    setSample(newSample)
    setBodyData(updatedBodyData);
  }
  const openScreen = (newOpen) => {
    setScreenPopup(newOpen);
  };
  function replacePlaceholders(text, values) {
    // Use a regular expression to find all placeholders like {{1}}, {{2}}, etc.
    const regex = /{{(\d+)}}/g;
    // Replace each placeholder with its corresponding value
    const replacedText = text.replace(regex, (match, index) => {
      const valueIndex = parseInt(index, 10) - 1; // Subtract 1 to convert to array index
      return values[valueIndex] !== undefined ? values[valueIndex].text : match;
    });
    return replacedText;
  }
  const { errors, values, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
  const handleConfirm = (value) => {
    setOpen(value);
    if (value) {
      setOpen(false)
      handleSubmit()
    }
  };
  const handleNameChange = (value) => {
    setFieldValue("name", value);
    handleChange(value, 'body', 0)
  }
  const resetData = () => {
    setCardData([]);
    setBodyData([]);
    setSample('');
    setOldSample('');
    setSitutionData([]);
    setTitlesData([]);
    setSelectedBank({})
    setSelectedCard({})
    setFieldValue('sitution_id','')
    setFieldValue('bank_id','')
    setFieldValue('card_id','')
    setFieldValue('sitution_id','')
    setFieldValue('name','')
    setFieldValue('mobile','')
    setSelectedSitution({})
    setSelectedTemplate({})
  }

  return (
    <Box style={{ backgroundColor: '#FFFFFF', minHeight: '100vh', overflow: 'scroll' }}>
      <FormikProvider value={formik} >
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} sx={{ fontFamily: 'Metropolis' }}>
          <Grid container sx={{ background: ' #07090A', padding: '20px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '40px', padding: '10px' }}>
              <img src={Logo} alt='bankkaro logo' />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Typography sx={{ color: '#FFFFFF', fontSize: '24px', fontWeight: '600', fontFamily: 'Metropolis' }}>Welcome, {user_name}</Typography>
              <Grid container sx={{ color: '#FFFFFF' }} spacing={2} mt={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl sx={{ display: 'flex', }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{ display: 'flex', }}
                      {...getFieldProps('type')}
                      error={Boolean(touched.type && errors.type)}
                    >
                      <FormControlLabel value="cashkaro" control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked': { color: '#DB0011' } }} size="small"  onClick={()=>callDropDownApi('cashkaro')}/>} label={<span style={{ fontFamily: 'Metropolis', fontSize: '16px', fontWeight: '700' }}>CashKaro</span>} />
                      <FormControlLabel sx={{ marginLeft: '20%' }} value="bankkaro" control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked': { color: '#DB0011' } }} size="small" onClick={()=>callDropDownApi('bankkaro')}/>} label={<span style={{ fontFamily: 'Metropolis', fontSize: '16px', fontWeight: '700' }}>BankKaro</span>} />
                    </RadioGroup>
                  </FormControl>
                  <FormHelperText error={Boolean(touched.type && errors.type)}>
                    {touched.type && errors.type}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    // multiple
                    size="small"
                    options={bankData}
                    value={selectedBank}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(event, newValue) => {
                      setSelectedBank(newValue ? newValue : {})
                      setFieldValue("bank_id", newValue?.id);
                      handleCard(newValue ? newValue.id : 0)
                    }}
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Select bank"
                        sx={{
                          backgroundColor: 'white', borderRadius: '8px', '& input::placeholder': {
                            fontFamily: 'Metropolis',
                            fontSize: '12px',
                            fontWeight: '400',


                          }, '.MuiSvgIcon-root': { color: '#DB0011 !important' }
                        }}
                        error={Boolean(touched.bank_id && errors.bank_id)}
                      />
                    )}
                  />
                  <FormHelperText error={Boolean(touched.bank_id && errors.bank_id)}>
                    {touched.bank_id && errors.bank_id}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    // multiple
                    size="small"
                    value={selectedCard}
                    options={cardData}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(event, newValue) => {
                      setSelectedCard(newValue ? newValue : {})
                      setFieldValue("card_id", newValue?.id);
                      handleSitution(newValue ? newValue.id : 0)
                    }}
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Select card"
                        sx={{
                          backgroundColor: 'white', borderRadius: '8px', '& input::placeholder': {
                            fontFamily: 'Metropolis',
                            fontSize: '12px',
                            fontWeight: '400',


                          }, '.MuiSvgIcon-root': { color: '#DB0011 !important' }
                        }}
                        error={Boolean(touched.card_id && errors.card_id)}
                      />
                    )}
                  />
                  <FormHelperText error={Boolean(touched.card_id && errors.card_id)}>
                    {touched.card_id && errors.card_id}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Autocomplete
                    // multiple
                    size="small"
                    options={situtionData}
                    value={selectedSitution}
                    getOptionLabel={(option) => option?.sitution || ''}
                    onChange={(event, newValue) => {
                      setFieldValue("sitution_id", newValue?.id);
                      setSelectedSitution(newValue ? newValue : {})
                      situtionChange(newValue)
                    }}
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Select Scenario"
                        sx={{
                          backgroundColor: 'white', borderRadius: '8px', '& input::placeholder': {
                            fontFamily: 'Metropolis',
                            fontSize: '12px',
                            fontWeight: '400',


                          }, '.MuiSvgIcon-root': { color: '#DB0011 !important' }
                        }}
                        error={Boolean(touched.sitution_id && errors.sitution_id)}
                      />
                    )}
                  />
                  <FormHelperText error={Boolean(touched.sitution_id && errors.sitution_id)}>
                    {touched.sitution_id && errors.sitution_id}
                  </FormHelperText>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ background: ' #FFFFFF', paddingX: '20px', fontFamily: 'Metropolis', fontSize: '20px',  }}>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <span style={{ fontFamily: 'Metropolis', fontWeight: 600, color: '#000', fontSize: '20px' }}>User Info -</span>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  {/* <TextField
                    fullWidth
                    type="name"
                    size="small"
                    variant="outlined"
                    label="First name"
                    value={values?.name}
                    // {...getFieldProps('name')}
                    onChange={(e)=>handleNameChange(e.target.value)}
                    sx={{ borderRadius: '15px' }}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  /> */}

                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <CssTextField
                    fullWidth
                    type="text"
                    size="small"
                    variant="outlined"
                    label={<span style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Metropolis' }}>Encrypted phone number</span>}
                    {...getFieldProps('mobile')}
                    sx={{ borderRadius: '15px', }}
                    error={Boolean(touched.mobile && errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                  />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          {bodyData && bodyData.length > 0 ? (
            <Grid container sx={{ background: ' #FFFFFF', paddingX: '20px', fontFamily: 'Metropolis', fontSize: '20px', marginBottom: '20px' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Grid container spacing={2} mt={1}>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <span style={{ fontFamily: 'Metropolis', fontWeight: 600 }}>Verify Variables</span>
                  </Grid> */}
                  {bodyData.map((item, index) => (
                    !titlesData[index]?.check &&
                    <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                      <CssTextField
                        key={index}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={item.text}
                        required
                        // sx={{ marginBottom: '10px' }}
                        label={<span style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Metropolis' }}>{titlesData[index]?.value}</span>}
                        onChange={(e) => handleChange(e, 'body', index)}
                      />
                      <span id={`span_${index}`} style={{ marginBottom: '10px', fontSize: '12px', color: '#DB0011', display: 'none' }} >Please add value</span>
                    </Grid>

                  ))}
                </Grid>
              </Grid>

            </Grid>
          ) : null}
          <Grid container sx={{paddingX: '20px', marginTop: '20px',  marginBottom:'30%'}}>
            <Grid item xs={12} sm={12} md={4} lg={4} >
              <Link to="/logout" style={{ textDecoration: 'none', fontSize:'18px' }}>
                <span style={{ color: '#D41000' }}><LogOut /></span> <span style={{ color: '#07090A', fontFamily:'Metropolis', fontSize:'18px', fontWeight:'600', lineHeight:'16px' }}>Logout</span>
              </Link>
            </Grid>
          </Grid>
          <Grid container sx={{ background: '', position: 'fixed', bottom: '0', zIndex: 999 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container sx={{ background: '#D9E3EC', height: '85px', color: '#fff', borderRadius: '32px 32px 0px 0px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Fab variant="extended" type="button" sx={{ width: '40%', backgroundColor: '#07090A', color: 'white', fontSize: '14px', fontWeight: '500', fontFamily: 'Metropolis', ':hover': { bgcolor: '#07090A', color: '#FFFFFF', } }} onClick={() => openScreen(true)}>Preview</Fab>
                <Fab variant="extended" type="button" sx={{ width: '40%', backgroundColor: '#DB0011', color: 'white', whiteSpace: 'nowrap', fontSize: '14px', fontWeight: '500', fontFamily: 'Metropolis', ':hover': { bgcolor: '#07090A', color: '#FFFFFF', } }} onClick={() => setOpen(true)}>Send message</Fab>
              </Grid>
            </Grid>
          </Grid>

        </Form>

      </FormikProvider >
      <SwipeableDrawer
        anchor="bottom"
        open={screenPopup}
        onClose={() => openScreen(false)}
        onOpen={() => openScreen(true)}
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ width: '100%', marginX: '10px', zIndex: 999999 }}
        PaperProps={{ elevation: 10, style: { backgroundColor: "transparent" } }}
      >

        <ScreenPopup openScreen={openScreen} sample={sample} />
      </SwipeableDrawer>
      <Dialog open={open} onClose={() => handleConfirm(false)} maxWidth="xs" fullWidth={false} sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px',
        },
      }}>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center', fontWeight: '700', fontSize: '20px', marginBottom: '10px', color: '#262626', fontFamily: 'Metropolis', lineHeight: '24px' }}>
            Are you sure you want to send this message?
          </DialogContentText>

        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '10px' }}>
          <Button sx={{ borderRadius: '40px', minWidth: '45%', color: '#DB0011', borderColor: '#DB0011', fontSize: '14px', fontWeight: '500', fontFamily: 'Metropolis', padding: '13px 8px' }} variant="outlined" onClick={() => handleConfirm(false)}>No, Cancel</Button>
          <Button sx={{ borderRadius: '40px', minWidth: '45%', background: '#DB0011', fontSize: '14px', fontWeight: '500', fontFamily: 'Metropolis', padding: '13px 8px' }} variant="contained" onClick={() => handleConfirm(true)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <SuccessPopup openSuccess={openSuccess} setOpenSuccess={setOpenSuccess} />
      <Alert open={alert} type={type} setOpen={setAlert} message={message} />
    </Box >
  );
}