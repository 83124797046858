import React, { useState } from "react";
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import OTPInput, { ResendOTP } from "otp-input-react";
import LoadingButton from '@mui/lab/LoadingButton';
import CryptoJS from "crypto-js";
export default function Otp(props) {
    const navigate = useNavigate();
    const { email, setOpenMail } = props
    const [OTP, setOTP] = useState("");
    const [otpLoad, setOTPLoad] = useState(false)


    const renderButton = (buttonProps) => {
        return <button style={{ ...buttonProps.remainingTime !== 0 ? { border: 'none', background: 'none', fontWeight: '600', fontFamily: 'Metropolis', color: '#707070', fontSize: '14px' } : { border: 'none', fontSize: '14px', background: 'none', color: '#707070' } }}  {...buttonProps}>Resend {buttonProps.remainingTime !== 0 ? <> in {buttonProps.remainingTime}s</> : ''}</button>
    };
    const renderTime = (remainingtime) => {
        return <span style={{ color: '#000', fontSize: '14px', fontWeight: '500', fontFamily: 'Metropolis' }}>Haven't received OTP?</span>
    };
    const maskEmail =(email)=> {
        const atIndex = email.indexOf('@');
        if (atIndex >= 0) {
          const prefix = email.substring(0, atIndex);
          const domain = email.substring(atIndex);
      
          // Keep the first two characters in the prefix and replace the rest with asterisks
          const maskedPrefix = prefix.substring(0, 2) + '*'.repeat(prefix.length - 2);
      
          // Combine the masked prefix with the domain
          return maskedPrefix + domain;
        } else {
          // Invalid email format, return the original email
          return email;
        }
      }

    const handleOTPSubmit = () => {
        if (OTP.length === 6) {
            setOTPLoad(true)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: email, otp: OTP })
            };

            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/auth/login`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    setOTPLoad(false)
                    if (Object.prototype.hasOwnProperty.call(data, "token")) {
                        localStorage.setItem("wd_token", data.token);
                        localStorage.setItem("wd_bki", data.user.id);
                        localStorage.setItem("wd_bkn", data.user.name);
                        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data.user), 'secret key 123').toString();
                        localStorage.setItem("wd_bundle", ciphertext);
                        props.handleDataSubmit(true)
                        props.setAlert(true);
                        props.setType('success');
                        props.setMessage('Login Successfully');
                    } else {
                        props.setAlert(true);
                        props.setType('error');
                        props.setMessage(data.message);
                    }

                });
        } else {
            props.setAlert(true);
            props.setAlertMessage('Invalid OTP')
        }

    }
    const handleOTPChange = (e) => {
        setOTP(e)
    }
    const handleResendClick = async () => {

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email }),
        };
        fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/auth/forgot-password`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log("data ... ", data)
                setOTPLoad(false)
                if ('success' in data) {
                    props.setAlert(true);
                    props.setType('success');
                    props.setMessage(data.message);
                } else {
                    props.setAlert(true);
                    props.setType('error');
                    props.setMessage(data.message);
                }
            });
    }
    const OTPComponent = () => {
        return (
            <OTPInput inputStyles={{ width: '60%', borderRadius: '10px', border: '1px solid #D9E3EC', background: '#E7E7E7', marginRight: '10px',marginTop: '10px', fontSize: "20px", width: "40px", height: "40px" }} value={OTP} onChange={(e) => handleOTPChange(e)} autoFocus OTPLength={6} otpType="number" disabled={false} />
        )
    }
    return (
        <Grid container pl={1} sx={{ width: '100%', padding: '20px' }}>
            <Typography variant="h6" sx={{ fontFamily: 'Metropolis', fontSize: '24px', fontWeight: 700, lineHeight: '30px', letterSpacing: '-0.72px', color: '#000000' }}>Enter the OTP we have sent you</Typography>
            <Typography variant="h6" sx={{ fontFamily: 'Metropolis', fontSize: '14px', fontWeight: 500, lineHeight: '18px',  color: '#262626' }}>Code sent to {maskEmail(email)}</Typography>
            <Grid item xs={12} mt={3}>
                <Box >
                    {OTPComponent()}
                </Box>
                <Grid item xs={11}  pt={1}>
                    <ResendOTP maxTime={30} renderButton={renderButton} renderTime={renderTime} onResendClick={handleResendClick} />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'start', marginTop: '10px', marginBottom: '20px' }} >
                <LoadingButton loading={otpLoad} variant="contained" size="medium" onClick={handleOTPSubmit} sx={{
                    width: '196px', height: '56px', fontSize: '12px', // Adjust the font size as needed
                    fontWeight: 500, // Adjust the font size as needed
                    fontStyle: 'normal', // Adjust the font size as needed
                    color: 'var(--Error-Red, #D41000)',
                    lineHeight: '20px', bottom: '-20px', borderRadius: '40px',
                    padding: '8px', backgroundColor: '#DB0011', color: 'white', ':hover': { bgcolor: '#DB0011', color: '#FFFFFF', }
                }}>Verify</LoadingButton>
            </Grid>
        </Grid>
    );

}
