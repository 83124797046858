import * as Yup from 'yup';
// add validation to driver form
const ageRegx=/^[0-9]{2}[-][0-9]{2}$/;
const templateFormSchema = Yup.object().shape({
    // card_id: Yup.string().required('Card is required').max(255),
    // card_id:Yup.array().of(Yup.string().required('Card are required')),
    sitution: Yup.string().required('Sitution is required'),
    // template: Yup.string().required('Template is required'),
});

export default templateFormSchema;
