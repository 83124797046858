import React, { useRef , useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import UserSchema from '../../validations/userSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import AWS from 'aws-sdk';
import LoadingButton from '@mui/lab/LoadingButton';
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Create(props) {
    const dialogBox = useRef()
    const { openPopup, setOpenPopup } = props;
    const [apiErrors, setApiErrors] = useState([]);
    const bundle = localStorage.getItem("wd_token");
    const [isLoading, setLoading] = useState(false);
    // const login_type = localStorage.getItem("bka");
  
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            password: '',
        },
        enableReinitialize: true,
        validationSchema: UserSchema,

        onSubmit: async (initialValues, { resetForm }) => {
            setLoading(true)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };

            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/users`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if ('error' in data) {
                        resetForm();
                        setApiErrors(data.errors);
                    } else if('success' in data) {
                        resetForm();
                        setOpenPopup(false);
                        props.setAlert(true);
                        props.refreshTable();
                        props.setType('success');
                        props.setMessage(data.message);
                    } else {
                        resetForm();
                        setOpenPopup(false);
                        props.setAlert(true);
                        props.setType('error');
                        props.setMessage('Something went wrong');
                    }
                    setLoading(false)
                });
            // navigate('/bank-info', { state: { path: 'login' } });

        }
    });
 
    const { errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Dialog
            ref={dialogBox}
            open={openPopup}
            onClose={() => { setOpenPopup(false) }}
            maxWidth="md"
        >
            <FormikProvider value={formik}  >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '10px' }}>
                    <DialogContent >
                        <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Add Caller</Typography>
                        <Grid container spacing={3}>
                            {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color:'#FFFFFF' }}>
                                    {
                                        Object.keys(apiErrors).map((item, i) => (
                                            <Typography key={i}>{apiErrors[item]}</Typography>
                                        ))
                                    }
                                </Box>
                            </Grid>}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Name"
                                    {...getFieldProps('name')}
                                    size="small"
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Email"
                                    {...getFieldProps('email')}
                                    size="small"
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Mobile"
                                    {...getFieldProps('mobile')}
                                    size="small"
                                    error={Boolean(touched.mobile && errors.mobile)}
                                    helperText={touched.mobile && errors.mobile}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Password"
                                    {...getFieldProps('password')}
                                    size="small"
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                />
                            </Grid>
                         
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="button"  color="primary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }} onClick={() => setOpenPopup(!openPopup) }>Cancel</Button>
                        <LoadingButton loading={isLoading} variant="contained" type="submit"  color="secondary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }}>Save</LoadingButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}