import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SubMenu = ({ item, open }) => {
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);
    const path = window.location.pathname;
    
    const inactiveColor = { color: '#07090A' };
    const activeColor = { color: '#DB0011' };
    const inactiveColorTwo = { textDecoration: 'none', color: '#07090A' };
    const activeColorTwo = { textDecoration: 'none', color: '#DB0011' };
    return (
        <>
            <List to={item.path} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingY:'0px' }}
                component="nav" aria-labelledby="nested-list-subheader">
                <ListItem onClick={item.subNav && showSubnav}>
                <Link to={item.path}> <ListItemIcon sx={path === item.path ? activeColor : inactiveColor}>
                    {item.icon}
                    </ListItemIcon></Link>
                    <ListItemText >
                        <Link to={item.path} style={path === item.path ? activeColorTwo : inactiveColorTwo}>{item.title}</Link>
                        {open === true ? item.subNav && subnav
                            ? <ExpandLessIcon sx={{ float: 'right' }} />
                            : item.subNav
                                ? <ExpandMoreIcon sx={{ float: 'right' }} />
                                : null : null}
                    </ListItemText>
                </ListItem>
            </List>
            {subnav &&
                item.subNav.map((item, index) => {
                    return (
                        <List to={item.path} key={index}>
                            <ListItem style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                            <Link to={item.path}> <ListItemIcon style={path === item.path ? activeColorTwo : inactiveColorTwo} >
                                {item.icon}
                                </ListItemIcon></Link>
                                <Link to={item.path} style={path === item.path ? activeColorTwo : inactiveColorTwo} >{item.title}</Link>
                            </ListItem>

                        </List>
                    );
                })}
        </>
    );
};

export default SubMenu;
