import React, { useRef, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TemplateSchema from '../../validations/templateSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Loader from '../../components/loader';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import { sample } from 'lodash';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Create(props) {
    const dialogBox = useRef()
    const { openPopup, setOpenPopup, templates, cards } = props;
    const [apiErrors, setApiErrors] = useState([]);
    const [newTemp, setNewTemp] = useState([]);
    const [newFollowTemp, setNewFollowTemp] = useState([]);
    const [selectedCard, setSelectedCard] = useState([]);
    const [titles, setTitles] = useState([]);
    const [sampleData, setSampleData] = useState('');
    const [followSampleData, setFollowSampleData] = useState('');
    const [updateTemp, setUpdateTemp] = useState([]);
    const [updateFollowTemp, setUpdateFollowTemp] = useState([]);
    const bundle = localStorage.getItem("wd_token");
    const [isLoading, setLoading] = useState(false);
    const [templateData, setTemplateData] = useState({
        "type": "media_template",
        "template_name": "",
        "lang_code": "",
    });
    const [followTemplateData, setFollowTemplateData] = useState({
        "type": "media_template",
        "template_name": "",
        "lang_code": "",
    });
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [followupTemplate, setFollowupTemplate] = useState({})
    const formik = useFormik({
        initialValues: {
            // bank_id: '',
            card_ids: [],
            titles: [],
            sitution: '',
            template: '',
            sample: '',
            application_type: 'cashkaro',
            follow_up_template: '',
            follow_up_hour: ''
        },
        enableReinitialize: true,
        validationSchema: TemplateSchema,

        onSubmit: async (initialValues, { resetForm, setErrors }) => {
            // setLoading(true)
            let arr = newTemp
            let arrFollow = newFollowTemp
            let checkSuccess = true
            let span1 = document.getElementById("card");
            let span2 = document.getElementById("template");
            if (selectedCard.length == '0') {
                span1.style.display = "block"
                checkSuccess = false
            } else {
                span1.style.display = "none"
            }
            if (!initialValues?.template) {
                span2.style.display = "block"
                checkSuccess = false
            } else {
                span2.style.display = "none"
            }
            if (!checkSuccess) {
                return
            }
            // This is for main template
            await arr.map(async (item, index) => {
                await updateTemp.map((element, index2) => {
                    if (element.type == 'body' && item?.body) {
                        item.body[element.index].text = element.value
                    } else if (element.type == 'header_text' && item?.header) {
                        item.header[element.index].text = element.value
                    } else if (element.type == 'header_image' && item?.header) {
                        item.header[element.index].image.link = element.value
                    } else if (element.type == 'header_video' && item?.header) {
                        item.header[element.index].video.link = element.value
                    } else if (element.type == 'button' && item?.button) {
                        item.button[element.index].url = element.value
                    }
                })
            })
            let newTemplate = templateData
            await arr.map((item, index) => {
                if (item?.body) {
                    newTemplate.body = item.body
                }
                if (item?.header) {
                    newTemplate.header = item.header
                }
                if (item?.button) {
                    newTemplate.button = item.button
                }
                if (item?.footer) {
                    // newTemplate.footer = item.footer
                }
            });
            // This is for Follow Up template
            if (arrFollow && arrFollow.length > 0) {
                await arrFollow.map(async (item, index) => {
                    await updateFollowTemp.map((element, index2) => {
                        if (element.type == 'body' && item?.body) {
                            item.body[element.index].text = element.value
                        } else if (element.type == 'header_text' && item?.header) {
                            item.header[element.index].text = element.value
                        } else if (element.type == 'header_image' && item?.header) {
                            item.header[element.index].image.link = element.value
                        } else if (element.type == 'header_video' && item?.header) {
                            item.header[element.index].video.link = element.value
                        } else if (element.type == 'button' && item?.button) {
                            item.button[element.index].url = element.value
                        }
                    })
                })

                let newFollowTemplate = followTemplateData
                await arrFollow.map((item, index) => {
                    if (item?.body) {
                        newFollowTemplate.body = item.body
                    }
                    if (item?.header) {
                        newFollowTemplate.header = item.header
                    }
                    if (item?.button) {
                        newFollowTemplate.button = item.button
                    }
                    if (item?.footer) {
                        // newFollowTemplate.footer = item.footer
                    }
                });
                initialValues.follow_up_template = JSON.stringify(newFollowTemplate)
            }

            ////////////////////////////////////////////////////////////////////////////////////
            initialValues.template = JSON.stringify(newTemplate)
            initialValues.titles = JSON.stringify(titles)
            // initialValues.card_id = selectedCard
            console.log("initialValues : ", initialValues)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };
            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if ('error' in data) {
                        resetForm();
                        setApiErrors(data.errors);
                    } else if ('success' in data) {
                        resetForm();
                        setOpenPopup(false);
                        props.setAlert(true);
                        props.refreshTable();
                        props.setType('success');
                        props.setMessage(data.message);
                    } else {
                        resetForm();
                        setOpenPopup(false);
                        props.setAlert(true);
                        props.setType('error');
                        props.setMessage('Something went wrong');
                    }
                    setLoading(false)
                });

        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
    const modifyTemplate = async (temp) => {
        let newTemplate = []
        let body = []
        let headers = []
        let footers = []
        let buttons = []
        let sample = '';
        if (temp?.components && temp?.components.length > 0) {
            let components = temp.components;
            await components.map(async (item, index) => {
                console.log(components[index])
                if (item?.example && item?.text && item?.type == 'BODY') {
                    if (item?.example?.body_text && item?.example?.body_text.length > 0) {
                        await item?.example?.body_text[0].map(element => {
                            body.push({ "text": element })
                        })
                    }
                    sample = item?.text
                } else if (item?.buttons && item?.buttons.length > 0 && item?.type == 'BUTTONS') {
                    await item?.buttons.map((element, index) => {
                        const hasDoubleBraces = element?.url.includes("{{") && element?.url.includes("}}");
                        if (hasDoubleBraces) {
                            buttons.push({ "button_no": `${index}`, "url": element.url })
                        }
                    })
                } else if (item?.type == 'HEADER') {
                    if (item.format == 'IMAGE') {
                        headers.push({ "image": { "link": "" } })
                    } else if (item.format == 'TEXT') {
                        const hasDoubleBraces = item?.text.includes("{{") && item?.text.includes("}}");
                        if (hasDoubleBraces) {
                            headers.push({ "text": item?.text })
                        }
                    } else if (item.format == 'VIDEO') {
                        headers.push({ "video": { "link": "" } })
                    }
                } else if (item?.type == 'FOOTER') {
                    footers.push({ "text": item?.text })
                }
            })
            if (body?.length > 0) {
                newTemplate.push({ "body": body })
            }
            if (headers?.length > 0) {
                newTemplate.push({ "header": headers })
            }
            if (buttons?.length > 0) {
                newTemplate.push({ "button": buttons })
            }
            if (footers?.length > 0) {
                newTemplate.push({ "footer": footers })
            }

            setUpdateTemp([])
            setNewTemp(newTemplate)
            setTemplateData({
                ...templateData,
                template_name: temp.name,
                lang_code: temp.language
            });
            setTitles([])
            setFieldValue('sample', sample);
            setFieldValue('template', newTemplate);
            setSampleData(sample)
        } else {
            setUpdateTemp([])
            setTitles([])
            setNewTemp([])
            setTemplateData({
                "type": "media_template",
                "template_name": "",
                "lang_code": "",
            });
            setFieldValue('sample', '');
            setFieldValue('template', '');
            setSampleData('')

        }
    }
    const modifyFollowTemplate = async (temp) => {
        let newTemplate = []
        let body = []
        let headers = []
        let footers = []
        let buttons = []
        let sample = '';
        if (temp?.components && temp?.components.length > 0) {
            let components = temp.components;
            await components.map(async (item, index) => {
                console.log(components[index])
                if (item?.example && item?.text && item?.type == 'BODY') {
                    if (item?.example?.body_text && item?.example?.body_text.length > 0) {
                        await item?.example?.body_text[0].map(element => {
                            body.push({ "text": element })
                        })
                    }
                    sample = item?.text
                } else if (item?.buttons && item?.buttons.length > 0 && item?.type == 'BUTTONS') {
                    await item?.buttons.map((element, index) => {
                        const hasDoubleBraces = element?.url.includes("{{") && element?.url.includes("}}");
                        if (hasDoubleBraces) {
                            buttons.push({ "button_no": `${index}`, "url": element.url })
                        }
                    })
                } else if (item?.type == 'HEADER') {
                    if (item.format == 'IMAGE') {
                        headers.push({ "image": { "link": "" } })
                    } else if (item.format == 'TEXT') {
                        const hasDoubleBraces = item?.text.includes("{{") && item?.text.includes("}}");
                        if (hasDoubleBraces) {
                            headers.push({ "text": item?.text })
                        }
                    } else if (item.format == 'VIDEO') {
                        headers.push({ "video": { "link": "" } })
                    }
                } else if (item?.type == 'FOOTER') {
                    footers.push({ "text": item?.text })
                }
            })
            if (body?.length > 0) {
                newTemplate.push({ "body": body })
            }
            if (headers?.length > 0) {
                newTemplate.push({ "header": headers })
            }
            if (buttons?.length > 0) {
                newTemplate.push({ "button": buttons })
            }
            if (footers?.length > 0) {
                newTemplate.push({ "footer": footers })
            }
            setUpdateFollowTemp([])
            setNewFollowTemp(newTemplate)
            setFollowTemplateData({
                ...templateData,
                template_name: temp.name,
                lang_code: temp.language
            });
            setFollowSampleData(sample)
            setFieldValue('follow_up_template', newTemplate);
        } else {
            setUpdateFollowTemp([])
            setFollowSampleData('')
            setNewFollowTemp([])
            setFollowTemplateData({
                "type": "media_template",
                "template_name": "",
                "lang_code": "",
            });
        }
    }
    const handleChange = (e, type, index) => {
        const updatedArray = [...updateTemp];
        let value = e.target.value
        const existingIndex = updatedArray.findIndex((item) => item.type === type && item.index === index);
        if (existingIndex !== -1) {
            updatedArray[existingIndex].value = e.target.value;
        } else {
            updatedArray.push({ type, index, value });
        }
        setUpdateTemp(updatedArray);
    }
    const handleFollowChange = (e, type, index) => {
        const updatedArray = [...updateFollowTemp];
        let value = e.target.value
        const existingIndex = updatedArray.findIndex((item) => item.type === type && item.index === index);
        if (existingIndex !== -1) {
            updatedArray[existingIndex].value = e.target.value;
        } else {
            updatedArray.push({ type, index, value });
        }
        setUpdateFollowTemp(updatedArray);
    }
    const handleTitles = (e, type, index) => {
        const updatedTitle = [...titles];
        if (type == 'title') {
            let value = e.target.value;
            let check = updatedTitle[index]?.check ? updatedTitle[index].check : false
            updatedTitle[index] = { value, check }
        } else {
            let check = e.target.checked;
            let value = updatedTitle[index]?.value ? updatedTitle[index].value : ''
            updatedTitle[index] = { value, check }
        }
        setTitles(updatedTitle)
    }
    const handleClose = () => {
        setUpdateFollowTemp([])
        setFollowSampleData('')
        setNewFollowTemp([])
        setFollowTemplateData({
            "type": "media_template",
            "template_name": "",
            "lang_code": "",
        });
        setUpdateTemp([])
        setTitles([])
        setNewTemp([])
        setTemplateData({
            "type": "media_template",
            "template_name": "",
            "lang_code": "",
        });
        setFollowupTemplate({})
        setSelectedTemplate({})
        setFieldValue('sample', '');
        setFieldValue('template', '');
        setSampleData('')
        setOpenPopup(!openPopup)
    }

    const formatText = (inputText) => {
        let formattedText = inputText.replace(/\*(.*?)\*/g, '<b>$1</b>');
        formattedText = formattedText.replace(/_(.*?)_/g, '<i>$1</i>');
        formattedText = formattedText.replace(/~(.*?)~/g, '<s>$1</s>');
        formattedText = formattedText.replace(/`(.*?)`/g, '<code>$1</code>');
        formattedText = formattedText.replace(/\n/g, '<br>');
        return { __html: `${formattedText}` };
    }
    return (
        <Dialog
            ref={dialogBox}
            open={openPopup}
            onClose={() => { setOpenPopup(false) }}
            maxWidth="md"
        >
            {isLoading === true ? <Loader show={isLoading} /> :
                <FormikProvider value={formik}  >
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '10px' }}>
                        <DialogContent >
                            <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Add Template</Typography>
                            <Grid container spacing={2}>
                                {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                                    <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color: '#FFFFFF' }}>
                                        {
                                            Object.keys(apiErrors).map((item, i) => (
                                                <Typography key={i}>{apiErrors[item]}</Typography>
                                            ))
                                        }
                                    </Box>
                                </Grid>}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Enter Situation"
                                        {...getFieldProps('sitution')}
                                        size="small"
                                        error={Boolean(touched.sitution && errors.sitution)}
                                        helperText={touched.sitution && errors.sitution}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard2"
                                        options={cards}
                                        size="small"
                                        getOptionLabel={(option) => option?.name || ''}
                                        onChange={(event, newValue) => {
                                            setSelectedCard(newValue)
                                            const resp = newValue.map(item => item?.id)
                                            setFieldValue("card_ids", resp);
                                            document.getElementById("card").style.display = "none"
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select Card"
                                                placeholder="Cards"
                                                error={Boolean(touched.card_ids && errors.card_ids)}
                                            // helperText={touched.card_ids && errors.card_ids}
                                            />
                                        )}
                                    />
                                    <span id="card" style={{ marginBottom: '10px', fontSize: '12px', color: '#DB0011', display: 'none' }} >Please choose card</span>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Autocomplete
                                        size="small"
                                        options={templates?.data}
                                        value={selectedTemplate}
                                        getOptionLabel={(option) => option?.name || ''}
                                        onChange={(event, newValue) => {
                                            setSelectedTemplate(newValue ? newValue : {})
                                            setFieldValue("template", newValue?.id);
                                            modifyTemplate(newValue)
                                            document.getElementById("template").style.display = "none"
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select template"
                                                placeholder="template"
                                                error={Boolean(touched.template && errors.template)}
                                            // helperText={touched.template && errors.template}
                                            />
                                        )}
                                    />
                                    <span id="template" style={{ marginBottom: '10px', fontSize: '12px', color: '#DB0011', display: 'none' }} >Please choose template</span>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div dangerouslySetInnerHTML={formatText(sampleData)} style={{ fontSize: '10px' }}>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {newTemp && newTemp.length > 0 ? (
                                        newTemp.map((item, outerIndex) => (
                                            <div key={outerIndex}>
                                                {item?.body && item?.body.length > 0 ? (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <b>Body variables</b>
                                                        </Grid>
                                                        {item.body.map((innerItem, innerIndex) => (
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                                        <TextField
                                                                            key={innerIndex}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            sx={{ marginBottom: '10px' }}
                                                                            label={`Title ${innerIndex + 1}`}
                                                                            onChange={(e) => handleTitles(e, 'title', innerIndex)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={8} sm={8} md={4} lg={4}>
                                                                        <TextField
                                                                            key={innerIndex}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            sx={{ marginBottom: '10px' }}
                                                                            label={`variable ${innerIndex + 1}`}
                                                                            onChange={(e) => handleChange(e, 'body', innerIndex)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                        <FormGroup>
                                                                            <FormControlLabel control={<Checkbox
                                                                                onChange={(e) => handleTitles(e, 'check', innerIndex)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />} label="For Agent Name" />
                                                                        </FormGroup>

                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                ) : null}
                                                {item?.header && item?.header.length > 0 ? (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <b>Header variables</b>
                                                        </Grid>
                                                        {item.header.map((innerItem, innerIndex) => (
                                                            innerItem?.image ?
                                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                    <TextField
                                                                        key={innerIndex}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        sx={{ marginBottom: '10px' }}
                                                                        label={`Image link ${innerIndex + 1}`}
                                                                        onChange={(e) => handleChange(e, 'header_image', innerIndex)}
                                                                    /></Grid> :
                                                                innerItem?.video ?
                                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                        <TextField
                                                                            key={innerIndex}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            sx={{ marginBottom: '10px' }}
                                                                            label={`Video link ${innerIndex + 1}`}
                                                                            onChange={(e) => handleChange(e, 'header_video', innerIndex)}
                                                                        /></Grid> :
                                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                        <TextField
                                                                            key={innerIndex}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            sx={{ marginBottom: '10px' }}
                                                                            label={`variable ${innerIndex + 1}`}
                                                                            onChange={(e) => handleChange(e, 'header_text', innerIndex)}
                                                                        />
                                                                    </Grid>
                                                        ))}
                                                    </Grid>
                                                ) : null}
                                                {item?.button && item?.button.length > 0 ? (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <b>Buttons variables</b>
                                                        </Grid>
                                                        {item.button.map((innerItem, innerIndex) => (
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <TextField
                                                                    key={innerIndex}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ marginBottom: '10px' }}
                                                                    label={`variable ${innerIndex + 1}`}
                                                                    onChange={(e) => handleChange(e, 'button', innerIndex)}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                ) : null}
                                            </div>
                                        ))
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={2}>
                                <Grid xs={12} sm={12} md={12} lg={12}>
                                    <b>Follow up Template</b>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Autocomplete
                                        size="small"
                                        options={templates?.data}
                                        value={followupTemplate}
                                        getOptionLabel={(option) => option?.name || ''}
                                        onChange={(event, newValue) => {
                                            setFollowupTemplate(newValue ? newValue : {})
                                            modifyFollowTemplate(newValue)
                                            // document.getElementById("template").style.display = "none"
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select FollowUp template"
                                                placeholder="Follow Up Template"
                                                error={Boolean(touched.follow_up_template && errors.follow_up_template)}
                                            // helperText={touched.template && errors.template}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        label="Enter FollowUp Hour"
                                        {...getFieldProps('follow_up_hour')}
                                        size="small"
                                        error={Boolean(touched.follow_up_hour && errors.follow_up_hour)}
                                        helperText={touched.follow_up_hour && errors.follow_up_hour}
                                    />
                                    {/* <span id="template" style={{ marginBottom: '10px', fontSize: '12px', color: '#DB0011', display: 'none' }} >Please choose template</span> */}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div dangerouslySetInnerHTML={formatText(followSampleData)} style={{ fontSize: '10px' }}>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {newFollowTemp && newFollowTemp.length > 0 ? (
                                        newFollowTemp.map((item, outerIndex) => (
                                            <div key={outerIndex}>
                                                {item?.body && item?.body.length > 0 ? (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <b>Body variables</b>
                                                        </Grid>
                                                        {item.body.map((innerItem, innerIndex) => (
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <TextField
                                                                    key={innerIndex}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ marginBottom: '10px' }}
                                                                    label={`variable ${innerIndex + 1}`}
                                                                    onChange={(e) => handleFollowChange(e, 'body', innerIndex)}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                ) : null}
                                                {item?.header && item?.header.length > 0 ? (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <b>Header variables</b>
                                                        </Grid>
                                                        {item.header.map((innerItem, innerIndex) => (
                                                            innerItem?.image ?
                                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                    <TextField
                                                                        key={innerIndex}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        sx={{ marginBottom: '10px' }}
                                                                        label={`Image link ${innerIndex + 1}`}
                                                                        onChange={(e) => handleFollowChange(e, 'header_image', innerIndex)}
                                                                    /></Grid> :
                                                                innerItem?.video ?
                                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                        <TextField
                                                                            key={innerIndex}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            sx={{ marginBottom: '10px' }}
                                                                            label={`Video link ${innerIndex + 1}`}
                                                                            onChange={(e) => handleFollowChange(e, 'header_video', innerIndex)}
                                                                        /></Grid> :
                                                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                        <TextField
                                                                            key={innerIndex}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            sx={{ marginBottom: '10px' }}
                                                                            label={`variable ${innerIndex + 1}`}
                                                                            onChange={(e) => handleFollowChange(e, 'header_text', innerIndex)}
                                                                        />
                                                                    </Grid>
                                                        ))}
                                                    </Grid>
                                                ) : null}
                                                {item?.button && item?.button.length > 0 ? (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <b>Buttons variables</b>
                                                        </Grid>
                                                        {item.button.map((innerItem, innerIndex) => (
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <TextField
                                                                    key={innerIndex}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ marginBottom: '10px' }}
                                                                    label={`variable ${innerIndex + 1}`}
                                                                    onChange={(e) => handleChange(e, 'button', innerIndex)}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                ) : null}
                                            </div>
                                        ))
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" type="button" color="primary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }} onClick={() => handleClose()}>Cancel</Button>
                            <LoadingButton loading={isLoading} variant="contained" type="submit" color="secondary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }}>Save</LoadingButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            }

        </Dialog >
    )
}