import React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

export default function Filter(props) {
    const { setFilters, onFilter} = props;
    const [values, setValues] = useState({
        name :'',
    });
    // const [btnLoad, setbtnLoad] = React.useState(false);
    const changeValue = (e, parm) => {
        setValues(prevState => ({...prevState, [parm]: e.target.value }))
    }
    const filterdata = () => {
        setFilters(values);
        onFilter(true);
    }
    const handleResetClick = () => {
        setValues({
            name :'',
        });
        setFilters({
            name :'',
        });
        onFilter(true);
    }
     
    return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Enter Product Name"
                        autoFocus
                        size="small"
                        value={values.name}
                        onChange={(e) => changeValue(e, 'name')}
                    />
                </Grid>
            
{/*               
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                        <Select
                            value={values.status}
                            label="Select Status"
                            onChange={(e) => changeValue(e, 'status')}
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                 */}
                <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="text"
                        sx={{color:'#1B5E28'}}
                        onClick={filterdata}
                    >
                        Search
                    </Button>
                    <Button
                        variant="text"
                        sx={{color:'#DB0011'}}
                        onClick={handleResetClick}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
    );
}
