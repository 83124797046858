import React, { useRef, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import BankSchema from '../../validations/bankSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Yup from 'yup';
import OTPScreen from './otp'
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
window.Buffer = window.Buffer || require("buffer").Buffer;

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '8px',
            padding: '18px 24px',
            border: '1px solid var(--Stroke-Grey, #D9E3EC)'
        },
        '&:hover fieldset': {
            borderRadius: '8px',
            border: '1px solid var(--Stroke-Grey, #D9E3EC)',
            color: '#DB0011'
        },
        '& .Mui-focused fieldset': {
            borderRadius: '8px',
            border: '1px solid var(--Stroke-Grey, #D9E3EC)',
            color: '#DB0011'
        },
        '& .MuiInputLabel-root': {
            fontFamily: 'Metropolis', // Adjust the font size as needed
            fontSize: '12px', // Adjust the font size as needed
            fontWeight: 400, // Adjust the font size as needed
            fontStyle: 'normal', // Adjust the font size as needed
            color: 'var(--Text-Inactive, #5C5C5C)'
        },
    },
});


export default function Create(props) {
    const dialogBox = useRef()
    const screenSize = useMediaQuery('(min-width:900px)');
    const { openMail, setOpenMail, handleLogin } = props;
    const [apiErrors, setApiErrors] = useState([]);
    const bundle = localStorage.getItem("wd_token");
    const [isLoading, setLoading] = useState(false);
    const [otpForm, setOtpForm] = useState(false);
    const [OTP, setOTP] = useState("");

    const [otpLoad, setOTPLoad] = useState(false)
    const [email, setEmail] = useState('');
    // const login_type = localStorage.getItem("bka");
    const FormShema = Yup.object().shape({
        email: Yup.string().required('Email ID is required').email('must be an vaild email').max(255),

    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        enableReinitialize: true,
        validationSchema: FormShema,

        onSubmit: async (initialValues, { resetForm, setErrors }) => {
            setLoading(true)
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(initialValues),
            };

            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/auth/forgot-password`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log("data ... ", data)
                    if ('error' in data) {
                        setLoading(false)
                        setErrors({email : data?.message});
                    } else if ('success' in data) {
                        resetForm();
                        setEmail(initialValues.email)
                        setOtpForm(true)
                        props.setAlert(true);
                        props.refreshTable();
                        props.setType('success');
                        props.setMessage(data.message);
                    } else {
                        resetForm();
                        console.log("asasss")
                        setOpenMail(false);
                        props.setAlert(true);
                        props.setType('error');
                        props.setMessage(data.message);
                    }
                    setLoading(false)
                });
            // navigate('/bank-info', { state: { path: 'login' } });

        }
    });
    const handleDataSubmit = (value) => {
        if (value) {
            setOtpForm(false)
            setOpenMail(true)
            handleLogin(true)
        } else {
            setOtpForm(false)
        }
    }
    const { errors, touched, handleSubmit, getFieldProps, setErrors } = formik;
    return (
        <Dialog
            ref={dialogBox}
            open={openMail}
            onClose={() => { setOpenMail(false) }}
            maxWidth={ "xs"}
        >
            <div style={{ textAlign: 'right' }}>
                <CloseIcon sx={{ color: '#000000', fontSize: '20px', marginRight: '15px',marginTop:'15px' }} onClick={() => setOpenMail(false)} />
            </div>

            {otpForm ?
                <OTPScreen email={email} handleDataSubmit={handleDataSubmit} setOpenMail={setOpenMail} setAlert={props.setAlert} setMessage={props.setMessage} setType={props.setType} /> :
                <FormikProvider value={formik}  >
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '16px' }}>
                        <DialogContent >
                            <Typography sx={{ color: "#000", fontFamily: 'Metropolis', fontWeight: 700, fontSize: '24px', lineHeight: '30px', }}>Enter your registered email ID</Typography>
                            <Typography sx={{ color: "#000", fontFamily: 'Metropolis', fontWeight: 600, fontSize: '12px', lineHeight: '16px', marginBottom: '10px' }}>We’ll send an SMS to verify</Typography>
                            <Grid container spacing={3}>
                                {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                                    <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color: '#FFFFFF' }}>
                                        {
                                            Object.keys(apiErrors).map((item, i) => (
                                                <Typography key={i}>{apiErrors[item]}</Typography>
                                            ))
                                        }
                                    </Box>
                                </Grid>}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <CssTextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Enter Email ID"
                                        {...getFieldProps('email')}
                                        sx={{ color: '#07090A', '& .MuiInputLabel-root': {
                                                fontFamily: 'Metropolis', // Adjust the font size as needed
                                                fontSize: '12px', // Adjust the font size as needed
                                                fontWeight: 400, // Adjust the font size as needed
                                                fontStyle: 'normal', // Adjust the font size as needed
                                                color: 'var(--Text-Inactive, #5C5C5C)',
                                                left: '10px'
                                            }
                                        }}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ display: 'flex', justifyContent: "start" , marginBottom:'10px'}} >
                                        <LoadingButton loading={isLoading} variant="contained" type="submit" sx={{
                                            width: '196px', height: '56px', fontSize: '12px', // Adjust the font size as needed
                                            fontWeight: 500, // Adjust the font size as needed
                                            fontStyle: 'normal', // Adjust the font size as needed
                                            color: 'var(--Error-Red, #D41000)',
                                            lineHeight: '20px', bottom: '-20px', borderRadius: '40px',
                                            padding: '8px', backgroundColor: '#DB0011', color: 'white', ':hover': { bgcolor: '#DB0011', color: '#FFFFFF', }
                                        }}>Verify</LoadingButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Form>
                </FormikProvider>
            }
        </Dialog>
    )
}