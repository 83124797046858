import React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function Filter(props) {
    const { setFilters, onFilter} = props;
    const [values, setValues] = useState({
        exit_id :'',
        mobile :'',
        product_name :'',
        vendor :'',
        status :'',
        start_at :'',
        end_at :'',
    });
    // const [btnLoad, setbtnLoad] = React.useState(false);
    const changeValue = (e, parm) => {
        setValues(prevState => ({...prevState, [parm]: e.target.value }))
    }
    const filterdata = () => {
        setFilters(values);
        onFilter(true);
    }
    const handleResetClick = () => {
        setValues({
            exit_id :'',
            mobile :'',
            product_name :'',
            vendor :'',
            status :'',
            start_at :'',
            end_at :'',
        });
        setFilters({
            exit_id :'',
            mobile :'',
            product_name :'',
            vendor :'',
            status :'',
            start_at :'',
            end_at :'',
        });
        onFilter(true);
    }
    const handleApply = (event, picker) => {
        picker.element.val(
        picker.startDate.format('MM/DD/YYYY') +
            ' - ' +
            picker.endDate.format('MM/DD/YYYY')
        );
        let daterange = event.target.value.split(' - ');
        let startDate = new Moment(daterange[0]).format('yyyy-MM-DD');
        let endDate = new Moment(daterange[1]).format('yyyy-MM-DD');
        setValues(prevState => ({ ...prevState, 'start_at' : startDate }))
        setValues(prevState => ({ ...prevState, 'end_at' : endDate }))
    }
    const handleCancel = (event, picker) => {
        picker.element.val('');
      };
    return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Enter Exit ID"
                        size="small"
                        value={values.value}
                        onChange={(e) => changeValue(e, 'exit_id')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        label="Enter Mobile Number"
                        size="small"
                        value={values.value}
                        onChange={(e) => changeValue(e, 'mobile')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Enter Product Name"
                        size="small"
                        value={values.value}
                        onChange={(e) => changeValue(e, 'product_name')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Select Eligibility Status</InputLabel>
                        <Select
                            value={values.card_status}
                            label="Select Status"
                            onChange={(e) => changeValue(e, 'status')}
                        >
                            <MenuItem value="" disabled>Status</MenuItem>
                            <MenuItem value="1">Accepted</MenuItem>
                            <MenuItem value="0">Declined</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
               
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <DateRangePicker  initialSettings={{ autoUpdateInput: false,
                        locale: {
                            cancelLabel: 'Clear',
                        }, }} 
                        onApply={ handleApply}
                        onCancel={handleCancel}
                    >
                        <input type="text" className="form-control col-4" placeholder="Date Range" defaultValue=""/>
                    </DateRangePicker>
                </Grid>
                
                <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="text"
                        sx={{color:'#1B5E28'}}
                        onClick={filterdata}
                    >
                        Search
                    </Button>
                    <Button
                        variant="text"
                        sx={{color:'#DB0011'}}
                        onClick={handleResetClick}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
    );
}
