import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useFormik, Form, FormikProvider } from 'formik';

import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Grid, Stack, Box, TextField } from '@mui/material';
import CreatePopup from './create';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07090A",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export default function Index() {
  const navigate = useNavigate();
  const dialogBox = useRef()
  const [open, setOpen] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [type, setType] = useState('');
  const [data, setData] = useState('');
  const [apiErrors, setApiErrors] = useState([]);
  const [onFilter, setOnFilters] = useState(false);
  const [message, setMessage] = useState('');
  const bundle = localStorage.getItem("wd_token");
  const mobileRegExp = /^[6-9]\d{9}$/;
  const formSchema = Yup.object().shape({
    mobile: Yup.string().required('Mobile number is required').matches(mobileRegExp, 'Mobile number is not valid'),
  })
  const formik = useFormik({
    initialValues: {
      mobile: '',
    },
    enableReinitialize: true,
    validationSchema: formSchema,

    onSubmit: async (initialValues, { resetForm }) => {
      setLoader(true)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bundle}`
        },
        body: JSON.stringify(initialValues)
      };

      fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/encryption`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if ('error' in data) {
            resetForm();
            setApiErrors(data.errors);
          } else if ('success' in data) {
            resetForm();
            setData(data.data);
          } else {
            resetForm();
            setAlert(true);
            setType('error');
            setMessage('Something went wrong');
          }
          setLoader(false)
        });
      // navigate('/bank-info', { state: { path: 'login' } });

    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;


  return (
    <>

      <Stack direction="row" justifyContent="space-between" >
        <Typography variant="h5" sx={{ paddingY: '10px' }}>Encryption</Typography>
      </Stack>

      <FormikProvider value={formik}  >
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '10px', }}>
          <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Encrypt Mobile Number</Typography>
          <Grid container spacing={3}>
            {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
              <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color: '#FFFFFF' }}>
                {
                  Object.keys(apiErrors).map((item, i) => (
                    <Typography key={i}>{apiErrors[item]}</Typography>
                  ))
                }
              </Box>
            </Grid>}
            <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Enter mobile number"
                {...getFieldProps('mobile')}
                size="small"
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>
          </Grid>
          <LoadingButton loading={isLoading} variant="contained" type="submit" color="secondary" sx={{ float: 'right', textTransform: 'capitalize' }}>Save</LoadingButton>
          {data && <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
            <b>Encrypted MobileNo. : </b> {data}
          </Grid>}
        </Form>
      </FormikProvider>
      <Alert open={alert} type={type} message={message} setOpen={setAlert} />
    </>
  );
}
