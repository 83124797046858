import React, { useRef, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { LogOut } from 'react-feather';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import Alert from './../components/alert'
import { TextField, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import Logo from '../images/BANKKARO.svg'

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Create() {
    const navigate = useNavigate();
    const bundle = localStorage.getItem("wd_token");
    const [isLoading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');

    // const login_type = localStorage.getItem("bka");
    // add validation to driver form

    const passwordSchema = Yup.object().shape({
        password: Yup.string().required('Password is required.').min(8, 'Password must be at least 8 characters long.'),
        confirm_password: Yup.string().required('Confirm Password is required.').oneOf([Yup.ref('password'), null], 'Passwords must match.')
    });
    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        enableReinitialize: true,
        validationSchema: passwordSchema,

        onSubmit: async (initialValues, { resetForm }) => {
            setLoading(true)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };

            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/users/update-password`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if ('success' in data) {
                        setAlert(true);
                        setType('success');
                        setMessage(data.message);
                        setTimeout(() => {
                            navigate('/', { replace: true });
                        }, 2000);
                    } else {
                        setAlert(true);
                        setType('error');
                        setMessage('Something went wrong');
                    }
                    setLoading(false)
                });
            // navigate('/bank-info', { state: { path: 'login' } });

        }
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Box style={{ backgroundColor: '#FFFFFF', minHeight: '100vh', overflow: 'scroll' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: '#07090A', textAlign: 'center' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: "space-between" }}>
                    <img src={Logo} alt='bankkaro logo' />

                    <Link to="/logout" style={{ textDecoration: 'none', fontSize: '12px' }}>
                        <span style={{ color: '#D41000' }}><LogOut /></span> <span style={{ color: '#FFFFFF' }}>Logout</span>
                    </Link>
                </Toolbar>
            </AppBar>
            <Grid container sx={{ marginTop: '70px', paddingX: '40px' }}>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
                    <Typography sx={{ fontSize: '24px', fontWeight: '700', fontFamily: 'Metropolis' }}>Update Password</Typography>
                    <FormikProvider value={formik}  >
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '10px' }}>
                            <Grid container spacing={3} mb={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Enter Password"
                                        {...getFieldProps('password')}
                                        size="small"
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Enter Confirm Password"
                                        {...getFieldProps('confirm_password')}
                                        size="small"
                                        error={Boolean(touched.confirm_password && errors.confirm_password)}
                                        helperText={touched.confirm_password && errors.confirm_password}
                                    />
                                </Grid>

                            </Grid>
                            <LoadingButton loading={isLoading} variant="contained" type="submit" color="secondary" sx={{float: 'right',  textTransform: 'capitalize',}}>Update</LoadingButton>
                            <Button variant="contained" type="button" color="primary" sx={{  textTransform: 'capitalize',}} onClick={() => navigate('/', { replace: true })}>Go Home</Button>
                        </Form>
                    </FormikProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
            <Alert open={alert} type={type} setOpen={setAlert} message={message} />
        </Box>
    )
}