import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MobileTheme from './mobileScreen'
import DesktopTheme from './desktopScreen'
import Loader from './../components/loader';
export default function Index() {
  const screenSize = useMediaQuery('(min-width:900px)');
  const navigate = useNavigate();
  const [bankData, setBankData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setLoader] = useState(false)
  const bundle = localStorage.getItem("wd_token");
  // const callDropDownApi = () => {
  //   setLoader(true);
  //   if (bundle != null) {
  //     // setAccessToken(bundle);
  //   } else {
  //     setAlert(true);
  //     setType('error');
  //     setMessage('No Token Found');
  //     navigate('/login', { replace: true });
  //   }
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Authorization": `Bearer ${bundle}`
  //     },
  //   };

  //   fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/templates/dropdown`, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if ('error' in data) {
  //         setLoader(false)
  //         setAlert(true);
  //         setType('error');
  //         setMessage(data.message);
  //       } else {
  //         if (data?.success == 1) {
  //           console.log("hello")
  //           setBankData(data?.data);
  //           setLoader(false);
  //         } else {
  //           setLoader(false)
  //           setAlert(true);
  //           setType('error');
  //           setMessage(data.message);
  //         }
  //       }
  //     });
  // }
  // useEffect(() => {
  //   callDropDownApi();
  // }, []);// eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      {isLoading === true ? <Loader show={isLoading} /> :
        <> {screenSize ? <DesktopTheme bankData={bankData} /> : <MobileTheme bankData={bankData} />}</>}

    </div>

  );
}
